import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import "./../Styles/organigramme.css";

const Organigramme = () => {
  return (
    <div className="Organigramme">
    <div className="content">
    <Tree
      lineWidth={"2px"}
      lineColor={"#63B143"}
      lineBorderRadius={"10px"}
      label={<div className="organigramme_div root">Exécutive Management</div>}
    >
      <TreeNode
        label={
          <div className="organigramme_div level_one">
            Technical Management Department
          </div>
        }
      >
        <TreeNode
          label={
            <div className="organigramme_div level_two">
              Software Development service
            </div>
          }
        >
          <div className="section">
              <div className="organigramme_div level_three">
                Web Development Unit
              </div>
              <div className="organigramme_div level_three">
                Mobile Development Unit
              </div>
              <div className="organigramme_div level_three">
                Technical Writing Unit
              </div>
          </div>
        </TreeNode>
        <TreeNode
          label={
            <div className="organigramme_div level_two">Operations Service</div>
          }
        >
          <div className="section">
            <div className="organigramme_div level_three">
                Database Administration Unit
            </div>
            <div className="organigramme_div level_three">
                CI/CD Pipelines Management Unit
            </div>
            <div className="organigramme_div level_three">
                System Security Unit
            </div>
            <div className="organigramme_div level_three">
                Technical Support Unit
            </div>
          </div>
        </TreeNode>
        <TreeNode
          label={
            <div className="organigramme_div level_two">Analytics Service</div>
          }
        >
            <div className="section">
              <div className="organigramme_div level_three">
                Reporting Unit
              </div>
              <div className="organigramme_div level_three">
                BI / Predicting
              </div>
            </div>
        </TreeNode>
      </TreeNode>
      <TreeNode
        label={
          <div className="organigramme_div level_one">
            Business Development Department
          </div>
        }
      >
        <div className="section">
            <div className="organigramme_div level_two">Accountant</div>
            <div className="organigramme_div level_two">Business Analyst</div>
            <div className="organigramme_div level_two">Business Developer</div>
            <div className="organigramme_div level_two">Forwarding</div>
            <div className="organigramme_div level_two">
                Building and Public Works
            </div>
        </div>
      </TreeNode>
      <TreeNode
        label={<div className="organigramme_div level_one">Agritech</div>}
      ></TreeNode>
    </Tree>
    </div>
    <div className="legend">
        <span className="legend_item">
            <span className="legend_color dg"></span>
            Direction générale
        </span>
        <span className="legend_item">
            <span className="legend_color departement"></span>
            Département
        </span>
        <span className="legend_item">
            <span className="legend_color service"></span>
            Service
        </span>
        <span className="legend_item">
            <span className="legend_color unite"></span>
            Unité
        </span>
    </div>
</div>
  );
};

export default Organigramme;
