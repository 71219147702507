import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Organigramme from "./Organigramme";
import OganigrammeImg from "./../Assets/Organigramme.png"

const NotreEquipe = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const element = document.querySelector(".AnimationDiv");
    const position = element.getBoundingClientRect();

    if (position.top < window.innerHeight) {
      setIsVisible(true);
    }
  };

  return (
    <div
      id="NotreEquipe"
      className={`NotreEquipe ${isVisible ? "AnimationDiv" : ""}`}
    >
      <div className="Whytitle">
        <div className="tiltleServ">
          <span>Organigramme de l'équipe</span>
        </div>
        <div className="descrServ">
          <p>
          Notre organisation repose sur une structure hiérarchique et collaborative qui favorise une gestion efficace et l'atteinte des objectifs stratégiques. Chaque département, service et unité joue un rôle clé dans le succès de nos projets.
          </p>
        </div>
      </div>
      <Organigramme />
      <img className="OganigrammeImg" src={OganigrammeImg} alt="Organigramme organisation TIR" />
    </div>
  );
};

export default NotreEquipe;
