import React, { useState, useEffect } from "react";
import CNRA from "../Assets/CNRA.png";
import MU2S from "../Assets/MU2S.png";
import SolutionCard from "./SolutionCard";
import Carousel from "react-multi-carousel";
import DomaineActivite from "./DomaineActivite";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const PROJECTS = [
  {
    image: CNRA,
    title: "Projet CNRA",
    subTitle: "",
    description:
      "Le projet CNRA est une solution technologique conçue pour centraliser et automatiser la collecte, l’analyse et le traitement des données spécifiques aux cultures d’hévéa. Ce système permet aux chercheurs et responsables du CNRA d'accéder facilement aux informations nécessaires, d'optimiser les processus de suivi et d’analyse des données, et de garantir une gestion plus rapide et précise des informations phénologiques et phytosanitaires.",
  },
  {
    image: MU2S,
    title: "Application MU2S Santé",
    subTitle: "",
    description:
      "L’application MU2S Santé est une plateforme numérique destinée aux retraités membres de la Mutuelle Sociale Santé (MU2S) afin de faciliter le renouvellement de leurs traitements pour les maladies chroniques telles que le diabète et l’hypertension. Elle vise à réduire les contraintes liées aux consultations médicales fréquentes en permettant la gestion numérique des prescriptions, la téléconsultation avec des médecins partenaires et l’accès simplifié aux médicaments via un réseau de pharmacies agréées. En optimisant ce processus, l’application contribue à améliorer l’accès aux soins tout en réduisant les coûts pour les adhérents et la mutuelle.",
  },
];

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const element = document.querySelector(".AnimationDiv");
    const position = element.getBoundingClientRect();

    if (position.top < window.innerHeight) {
      setIsVisible(true);
    }
  };
  return (
    <>
      <div
        id="Services"
        className={`Services pousserServices ${
          isVisible ? "AnimationDiv" : ""
        }`}
      >
        <div className="tiltleServ pousserTitle">
          <span>Nos domaines d'activités</span>
        </div>
        <div className="descrServ">
          <p>
            Notre expertise dans les domaines ci-dessous nous permet de créer
            des solutions digitales innovantes pour vous aider à atteindre vos
            objectifs.
          </p>
        </div>
        {/* <div className="ListServices">
          <div className="servicesEle">
            <div className="iconService">
              <img src={ingApp} alt="d’application mobile, web et UX." />
            </div>
            <div className="titleservices">
              <span>
                Ingénierie du développement d’application mobile, web et UX.
              </span>
            </div>
          </div>
          <div className="servicesEle">
            <div className="iconService">
              <img src={ingcyber} alt="d’application mobile, web et UX." />
            </div>
            <div className="titleservices">
              <span>
                Ingénierie <br />
                de la cybersécurité
              </span>
            </div>
          </div>
          <div className="servicesEle">
            <div className="iconService">
              <img src={incloud} alt="d’application mobile, web et UX." />
            </div>
            <div className="titleservices">
              <span>
                Ingénierie des <br />
                infrastructures cloud
              </span>
            </div>
          </div>
          <div className="servicesEle">
            <div className="iconService">
              <img src={inmicro} alt="d’application mobile, web et UX." />
            </div>
            <div className="titleservices">
              <span>
                Ingénierie logicielle <br /> et microservices
              </span>
            </div>
          </div>
        </div> */}
        <DomaineActivite />
      </div>

      <div className="servicedeploie  margin-to">
        <div
          className={`Services solutionsServices ${
            isVisible ? "AnimationDiv" : ""
          }`}
        >
          <div className="tiltleServ">
            <span>Nos solutions déjà déployées</span>
          </div>
          <div className="ListSolutions">
            <div className="solution_carousel">
              <Carousel
                responsive={responsive}
                autoPlaySpeed={2000}
                itemClass="px-1"
              >
                {PROJECTS.map((item, index) => (
                  <SolutionCard
                    key={index}
                    image={item?.image}
                    title={item?.title}
                    subTitle={item?.subTitle}
                    description={item?.description}
                    index={index}
                  />
                ))}
              </Carousel>
            </div>
            {PROJECTS.map((item, idex) => (
              <div className="project">
                <div className="image">
                  <img src={item?.image} alt="Visuel du projet" />
                </div>
                <div className="content">
                  <h2>{item?.title}</h2>
                  <p>{item?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
