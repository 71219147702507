import React, { useState } from "react";

const SolutionCard = ({ image, title, subTitle, description, index }) => {
    const [showPlus, setShowPlus] = useState({
        index: null,
        show: false,
    });

    const handleShow = () => {
        setShowPlus({
            index:index,
            show: !showPlus?.show,
        });
    };

  return (
    <div className="servicesElement">
      <div className="SolutionImage">
        <img src={image} alt="d’application mobile, web et UX." />
      </div>

      <div className="titlesolution">
        <div className="titilesolu">
          <span>
            {title} <br />
            <span>{subTitle && subTitle}&nbsp;</span>
          </span>
        </div>
        <div className={showPlus?.index === index && showPlus?.show ? "" : "card_description"}>
            <p className={showPlus?.index === index && showPlus?.show ? "textsolution" : "textsolution card_description_content"}>
            {description}
            </p>
        </div>
        <button onClick={() => handleShow()} className="contactNous">{showPlus?.index === index && showPlus.show ? "Afficher moins" : "Afficher plus"}</button>
      </div>
    </div>
  );
};

export default SolutionCard;
