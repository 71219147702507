import React from "react";

const DataIcon = (props) => {
  return (
    <svg
      fill="#ffffff"
      version="1.1"
      width={props.size}
      height={props.size}
      viewBox="0 0 100.787 100.787"
      {...props}
    >
      <g>
        <g>
          <path
            d="M99.288,94.894h-7.121V90.06c0-0.275-0.224-0.5-0.5-0.5s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5
			s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5
			s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5
			s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5s-0.5,0.225-0.5,0.5v4.834h-9V90.06c0-0.275-0.224-0.5-0.5-0.5
			s-0.5,0.225-0.5,0.5v4.834H3V83.561h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5
			s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5
			s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5
			s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5
			s-0.224-0.5-0.5-0.5H3V4.394c0-0.829-0.672-1.5-1.5-1.5S0,3.564,0,4.394v92c0,0.828,0.672,1.5,1.5,1.5h97.787
			c0.828,0,1.5-0.672,1.5-1.5S100.116,94.894,99.288,94.894z"
          />
          <rect x="15.644" y="16.497" width="10.25" height="67.795" />
          <rect x="37.394" y="42.791" width="10.25" height="41.5" />
          <rect x="58.895" y="28.541" width="10.25" height="55.75" />
          <rect x="81.395" y="35.291" width="10.25" height="49" />
        </g>
      </g>
    </svg>
  );
};

export default DataIcon;
