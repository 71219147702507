import React from "react";

const InfogerenceIcon = (props) => {
  return (
    <svg
      fill="#ffffff"
      version="1.1"
      width={props.size}
      height={props.size}
      viewBox="0 0 95.348 95.347"
      {...props}
    >
      <g>
        <g>
          <path
            d="M56.527,60.122l-4.662-1.033c-0.047-3.138-0.719-6.168-1.996-9.007l3.606-2.92c0.858-0.695,0.99-1.954,0.296-2.813
			l-4.521-5.584c-0.334-0.413-0.818-0.675-1.346-0.731c-0.525-0.057-1.056,0.102-1.468,0.435l-3.511,2.846l0,0
			c-2.486-1.907-5.277-3.259-8.297-4.019v-4.458c0-1.104-0.896-2-2-2h-7.183c-1.104,0-2,0.896-2,2v4.461
			c-3.08,0.777-5.922,2.171-8.447,4.144l-3.545-2.82c-0.415-0.33-0.94-0.479-1.472-0.422c-0.527,0.06-1.009,0.327-1.339,0.743
			l-4.472,5.623c-0.688,0.864-0.544,2.123,0.32,2.81l3.642,2.896l0,0c-1.25,2.848-1.895,5.879-1.916,9.01l-4.666,1.078
			c-1.076,0.25-1.747,1.322-1.499,2.398l1.616,7.001c0.249,1.077,1.325,1.747,2.399,1.499l4.813-1.111l0,0
			c1.429,2.682,3.344,5.018,5.691,6.943l-2.17,4.55c-0.476,0.997-0.054,2.19,0.943,2.666l6.484,3.095
			c0.271,0.129,0.566,0.194,0.861,0.194c0.226,0,0.451-0.038,0.668-0.114c0.5-0.178,0.909-0.545,1.138-1.024l2.198-4.611
			c2.923,0.563,5.966,0.554,8.879-0.032l2.236,4.584c0.484,0.994,1.685,1.403,2.675,0.922l6.456-3.148
			c0.992-0.484,1.405-1.682,0.921-2.674l-2.206-4.524c2.335-1.946,4.231-4.301,5.639-6.999l4.812,1.067
			c1.076,0.237,2.146-0.441,2.385-1.521l1.557-7.014c0.114-0.518,0.02-1.061-0.267-1.508C57.495,60.552,57.045,60.236,56.527,60.122
			z M37.856,59.435c0,4.859-3.953,8.812-8.813,8.812c-4.858,0-8.811-3.953-8.811-8.812s3.952-8.812,8.811-8.812
			C33.903,50.624,37.856,54.576,37.856,59.435z"
          />
          <path
            d="M61.943,42.999c0.746,0,1.463-0.42,1.807-1.139l1.054-2.208c1.826,0.353,3.735,0.345,5.551-0.021l1.07,2.195
			c0.483,0.992,1.682,1.405,2.675,0.921l2.691-1.313c0.477-0.233,0.842-0.646,1.015-1.147c0.172-0.501,0.139-1.051-0.095-1.528
			l-1.053-2.155c1.458-1.214,2.646-2.686,3.527-4.377l2.278,0.504c1.074,0.238,2.146-0.442,2.386-1.52l0.646-2.923
			c0.238-1.078-0.441-2.146-1.521-2.385l-2.184-0.484c-0.028-1.962-0.449-3.857-1.248-5.632l1.673-1.355
			c0.412-0.334,0.675-0.818,0.729-1.345c0.056-0.527-0.102-1.056-0.436-1.468l-1.884-2.327c-0.697-0.859-1.957-0.99-2.813-0.295
			l-1.614,1.307c-1.554-1.193-3.299-2.038-5.188-2.513V9.751c0-1.104-0.896-2-2-2h-2.994c-1.104,0-2,0.896-2,2v2.04
			c-1.927,0.486-3.703,1.358-5.28,2.592l-1.634-1.298c-0.862-0.687-2.12-0.543-2.81,0.32L52.43,15.75
			c-0.33,0.416-0.481,0.945-0.422,1.472c0.061,0.527,0.327,1.009,0.743,1.339l1.689,1.345c-0.78,1.779-1.184,3.676-1.197,5.636
			l-2.188,0.505c-0.518,0.119-0.965,0.439-1.246,0.889c-0.281,0.45-0.372,0.993-0.252,1.51l0.675,2.918
			c0.249,1.076,1.323,1.747,2.398,1.498l2.279-0.527c0.893,1.676,2.09,3.137,3.56,4.343l-1.035,2.17
			c-0.229,0.479-0.257,1.028-0.08,1.528c0.178,0.5,0.546,0.91,1.024,1.138l2.702,1.289C61.361,42.935,61.654,42.999,61.943,42.999z
			 M62.01,25.635c0-3.039,2.473-5.51,5.512-5.51c3.038,0,5.51,2.472,5.51,5.51c0,3.039-2.472,5.511-5.51,5.511
			C64.482,31.146,62.01,28.674,62.01,25.635z"
          />
          <path
            d="M93.782,64.115l-2.182-0.483c-0.028-1.961-0.449-3.856-1.25-5.632l1.675-1.355c0.412-0.334,0.675-0.818,0.73-1.346
			s-0.103-1.057-0.437-1.468l-1.885-2.327c-0.695-0.859-1.956-0.99-2.813-0.295l-1.613,1.307c-1.556-1.193-3.3-2.038-5.188-2.513
			v-2.039c0-1.104-0.896-2-2-2h-2.994c-1.104,0-2,0.896-2,2v2.041c-1.929,0.485-3.706,1.358-5.281,2.592h-0.001l-1.632-1.298
			c-0.415-0.331-0.938-0.482-1.472-0.422c-0.527,0.061-1.009,0.326-1.339,0.742l-1.863,2.343c-0.688,0.864-0.544,2.123,0.32,2.812
			l1.691,1.344c-0.782,1.785-1.187,3.681-1.199,5.637l-2.188,0.505c-0.517,0.12-0.965,0.438-1.246,0.89
			c-0.28,0.449-0.372,0.992-0.252,1.51l0.675,2.918c0.249,1.076,1.327,1.744,2.397,1.498l2.281-0.526
			c0.893,1.677,2.09,3.138,3.558,4.343h0.001l-1.035,2.168c-0.229,0.479-0.258,1.029-0.081,1.529c0.179,0.5,0.546,0.909,1.024,1.138
			l2.702,1.289c0.277,0.132,0.571,0.195,0.859,0.195c0.746,0,1.464-0.42,1.807-1.14l1.054-2.207
			c1.828,0.353,3.739,0.347,5.552-0.021l1.071,2.193c0.484,0.992,1.682,1.406,2.675,0.922l2.69-1.312
			c0.477-0.232,0.842-0.645,1.014-1.146c0.173-0.501,0.141-1.051-0.093-1.528l-1.052-2.155c1.459-1.215,2.645-2.688,3.524-4.377
			l2.278,0.506c0.52,0.115,1.061,0.02,1.508-0.267c0.447-0.285,0.763-0.735,0.878-1.254l0.647-2.923
			C95.542,65.422,94.861,64.355,93.782,64.115z M82.838,63.848c0,3.039-2.472,5.511-5.509,5.511c-3.038,0-5.512-2.472-5.512-5.511
			s2.474-5.511,5.512-5.511C80.366,58.338,82.838,60.81,82.838,63.848z"
          />
        </g>
      </g>
    </svg>
  );
};

export default InfogerenceIcon;
