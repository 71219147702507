import React from "react";

const SecurityIcon = (props) => {
  return (
    <svg
      height={props.size}
      width={props.size}
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
      fill="#ffffff"
    >
      <g>
        <path
          class="st0"
          d="M510.158,392.021l-45.585-57.325V93.613c0-11.662-9.457-21.12-21.119-21.12H68.546
		c-11.662,0-21.111,9.458-21.111,21.12v241.082L1.841,392.021C0.649,393.516,0,395.366,0,397.285v25.373
		c0,9.311,7.548,16.849,16.85,16.849H495.15c9.302,0,16.85-7.538,16.85-16.849v-25.373C512,395.366,511.351,393.516,510.158,392.021
		z M77.226,102.293h357.547v202.604H77.226V102.293z M304.122,419.469h-96.244v-25.478h96.244V419.469z"
        />
        <path
          class="st0"
          d="M291.552,186.314c0-9.786-3.994-18.734-10.417-25.14c-6.406-6.414-15.345-10.416-25.14-10.409
		c-9.786-0.008-18.734,3.994-25.131,10.409c-6.414,6.407-10.417,15.354-10.417,25.14v12.508h-11.472v43.38
		c0,12.571,10.193,22.746,22.762,22.746h48.533c12.561,0,22.754-10.175,22.754-22.746v-43.38h-11.472V186.314z M237.703,186.314
		c0-2.55,0.519-4.937,1.435-7.124c1.384-3.268,3.717-6.086,6.639-8.058c2.931-1.98,6.406-3.112,10.218-3.12
		c2.55,0,4.936,0.518,7.124,1.435c3.276,1.383,6.086,3.717,8.058,6.648c1.97,2.913,3.112,6.388,3.12,10.218v12.508h-36.594V186.314z
		"
        />
      </g>
    </svg>
  );
};

export default SecurityIcon;
