import React from "react";

import IconAcco from "../Assets/iconAcco.svg";
import temoin1 from "../Assets/temoin1.png";
import temoin2 from "../Assets/temoi2.png";
import temoin3 from "../Assets/temoin3.png";

const Temoignages = () => {
  return (
    <div id="Temoignages" className="Temoignages AnimationDiv">
      <div className="temoignagetitle">
        <div className="Whytitle">
          <div className="tiltleServ">
            <span>LES TÉMOIGNAGES</span>
          </div>
          <div className="descrServ">
            <p>
              Nos clients satisfaits comptent parmi eux des partenaires
              prestigieux qui nous ont fait confiance pour notre expertise et la
              qualité de nos services.
            </p>
          </div>
        </div>
      </div>
      <div className="barnnerTemoi">
        <div className="elementTemoi">
          <div className="Accola">
            <img src={IconAcco} alt="accolate" />
          </div>
          <div className="textTemoi">
            <p>
              TIR a implémenté la solution DPI pour notre hôpital avec succès.
              Leur équipe a travaillé en étroite collaboration avec nous pour
              personnaliser la solution en fonction de nos besoins et fournir
              une formation complète pour notre personnel. Depuis
              l'implémentation, nous avons constaté une amélioration
              significative de nos processus de gestion des patients et de la
              qualité de notre service.
            </p>
          </div>
          <div className="IdentiteTemoi">
            <div className="ImageTemoi">
              <img src={temoin2} alt="Temoin" />
            </div>
            <div className="NomTemoi">
              <div className="NomPrenom">
                <span>M. Kouassi Armand</span>
              </div>
              <div className="fonctionTemoi">
                <p>Mededin Généraliste </p>
              </div>
            </div>
          </div>
        </div>

        <div className="elementTemoi">
          <div className="Accola">
            <img src={IconAcco} alt="accolate" />
          </div>
          <div className="textTemoi">
            <p>
              Nous avons travaillé avec TIR pour développer notre application
              mobile et nous avons été très satisfaits de leur travail. L'équipe
              de TIR était très professionnelle et compétente, et nous avons
              apprécié leur approche personnalisée pour répondre à nos besoins
              spécifiques. Leur expertise en matière de développement
              d'applications mobiles et leur compréhension de l'expérience
              utilisateur ont été des atouts clés pour notre projet.
            </p>
          </div>
          <div className="IdentiteTemoi">
            <div className="ImageTemoi">
              <img src={temoin1} alt="Temoin" />
            </div>
            <div className="NomTemoi">
              <div className="NomPrenom">
                <span>Mme. Koné Aminata</span>
              </div>
              <div className="fonctionTemoi">
                <p>
                  Directrice générale de la société de distribution de produits
                  alimentaires
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="elementTemoi cacheDiv">
          <div className="Accola">
            <img src={IconAcco} alt="accolate" />
          </div>
          <div className="textTemoi">
            <p>
              Nous avons collaboré avec TIR pour améliorer la qualité de nos
              données et nous avons été très impressionnés par leur approche
              rigoureuse. Leur équipe de spécialistes en qualité de données a
              travaillé en étroite collaboration avec nous pour comprendre nos
              exigences et mettre en place des processus pour garantir la
              qualité de nos données. Nous recommandons vivement les services de
              TIR pour toute entreprise qui souhaite améliorer la qualité de ses
              données.
            </p>
          </div>
          <div className="IdentiteTemoi">
            <div className="ImageTemoi">
              <img src={temoin3} alt="Temoin" />
            </div>
            <div className="NomTemoi">
              <div className="NomPrenom">
                <span>M. Kouadio Augustin</span>
              </div>
              <div className="fonctionTemoi">
                <p>Directeur général de la société de télécommunications</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temoignages;
