import React from 'react'
import TIRlocal from '../Assets/TIR-local.jpg'

const Propos = () => {
  return (
    <div id='Propos' className='Propos AnimationDiv'>
      <div className="presentationTIR">
            <div className="tittleTIR">
                <span>QUI SOMMES NOUS ?</span>
            </div>
            <div className="descriptionTIR">
              <p>
                Nous sommes une <strong>Entreprise de Services Numériques (ESN)</strong> spécialisée dans
                l'accompagnement des entreprises à travers des solutions technologiques
                innovantes.<br />
                Nous accompagnons les entreprises, administrations
                et particuliers dans leurs projets de transformation
                digitale. <br />
                Spécialisés en e-Santé, ingénierie logicielle, data, cloud
                computing, formation, géomatique et acquisition de
                matériels informatiques, notre équipe pluridisciplinaire
                met un point d'honneur à la qualité et à la rigueur. <br />
                Nous privilégions la proximité et la disponibilité pour
                répondre au mieux à vos besoins.
              </p>
            </div>
      </div>
      <div className="imageTIR">
        <img src={TIRlocal} alt="" />
      </div>
    </div>
  )
}

export default Propos
