import React, {useState} from 'react'
import axios from 'axios'
import Emailsend from '../Assets/email01.gif'
import { CircularProgress } from '@mui/material';

import { FaMapMarkerAlt } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";

const Contacts = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const  toggleModal =()=> {
    setIsModalVisible(!isModalVisible);
  }

  const [Loading, setLoading] = useState(false)

  const handleWindowClick = (event) => {
      if (event.target.className === "modal show-modal") {
        toggleModal();
      }
    }

    const RechargerPage = () =>{
      toggleModal()
      window.location.reload(true);
    }
  
  const [FormData, setFormData] = useState({
    Nom : '',
    Prenom : '',
    Email : '',
    Contacts : '',
    descriptionContact : ''

  })
  const onSubmitForm = async (e) =>{
    e.preventDefault()
    setLoading(true);
    try{
      await axios({
        method: "post",
        url:"https://tir-ci.com/email.php",
        data : FormData,
       
      })
    }
    catch(error){
      console.log(error)
    }finally{
      setLoading(false)
       
          toggleModal()
        
    }
      
      

  }
  const ChangeDataForm = (e)=>{
    
    setFormData(FormData=>({...FormData, [e.target.name] : e.target.value}))
    
  }
  return (
    <div id="Contacts" className='Contacts AnimationDiv'>
      <div className="Contacttitle">
        <div className="Whytitle">
                <div className="tiltleServ">
                    <span>Contactez-nous</span>
                </div>
                <div className="descrServ">
                    <span>
                    Contactez-nous dès maintenant pour discuter de vos besoins
                    en matière de solutions digitales innovantes pour votre entreprise.

                    </span>
                </div>
        </div>
      </div>
      <div className="contactBack">
        <div className="backformulaire">
            <div className="backformImage">
              <div className="textformulaire">
                <div className="headerTextform">
                  <div className="titleform">
                    <span>COMMENT NOUS CONTACTER ?</span>
                  </div>
                  <div className="descriForm">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1986.6034798768055!2d-3.7621379616107675!3d5.230153895306691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1f78b85a945fd%3A0x67588c4b4e337d7f!2sTIR!5e0!3m2!1sfr!2sci!4v1679914687417!5m2!1sfr!2sci" width="600" height="450" title='carte TIR' allowFullScreen loading="lazy" referrerPolicy="no-referrer"></iframe>                 </div>
                </div>
                <div className="locationTIR">
                  <span className='siegespan'><FaMapMarkerAlt size={24} /></span>
                  <span className='descrisiege'>Village des Technologies de l’Information et de la Biotechnologie (VITIB)</span>
                </div>
                <div className="locationTIR">
                  <span className='siegespan'><FiPhone size={24} /></span>
                  <span className='descrisiege'>(+225) 27 21 392 900 / 27 21 312 946 / 07 07 190 505</span>
                  
                </div>
                <div className="locationTIR">
                  <span className='siegespan'><MdOutlineEmail size={24} /></span>
                  <span className='descrisiege'>info@tir-ci</span>
                </div>
              </div>
              <div className="formulaireIn">
               
                <div className="formInputtitle">
                  <span> Formulaire </span>
                </div>
                <form onSubmit={onSubmitForm} >
                  <div className="formualaires">
                      <div className="LigneOne">
                        <div className="NomInput">
                          <input type="text" name='Nom' placeholder='Entrer votrre nom' onChange={ChangeDataForm} required />
                        </div>
                        <div className="NomInput">
                          <input type="text" name='Prenom' placeholder='Entrer votrre Prenom' onChange={ChangeDataForm} required/>
                        </div>
                      </div>
                      <div className="LigneOne top-marg">
                        <div className="NomInput">
                          <input type="email" name='Email' placeholder='Entrer votrre Email' onChange={ChangeDataForm} required />
                        </div>
                        <div className="NomInput">
                          <input type="phone" name='Contacts' placeholder='Entrer votre Contact' onChange={ChangeDataForm} required/>
                        </div>
                        
                      </div>
                      <div className="textlong top-marg">
                        <div className="NomInput">
                          <textarea name="descriptionContact" id="descriptionContact" cols="30" rows="10" placeholder='Votre message' onChange={ChangeDataForm} required></textarea>
                        </div>
                      </div>
                      {Loading && <div className='CircularProgress'> <CircularProgress /> </div>}

                      <div className="btn-action">
                          <button className='contactNous orangeBack'>Contactez-nous</button>
                      </div>
                      
                    </div>
                </form>
                
              </div>
            </div>
        </div>
      </div>
      
      <div
        className={isModalVisible ? "modal show-modal" : "modal"}
        onClick={handleWindowClick}
      >
        <div className="modal-content">
          <span className="close-button" onClick={RechargerPage}>
            &times;
          </span>
          <div className="conteneurImageEmail">
            <img src={Emailsend} alt="send"  />
              <h2> <span className='FormDataPrenom'>{FormData.Prenom}</span>, Merci de nous avoir contacté</h2>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Contacts
