import React from 'react';
import CloudIcon from '../icons/CloudIcon';
import FormationIcon from '../icons/FormationIcon';
import DataIcon from '../icons/DataIcon';
import AppliIcon from '../icons/AppliIcon';
import SecurityIcon from '../icons/SecurityIcon';
import NetworkIcon from '../icons/NetworkIcon';
import InfogerenceIcon from '../icons/InfogerenceIcon';
import AgricoleIcon from '../icons/AgricoleIcon';

const DomaineActivite = () => {
    return (
        <div className='DomaineActivite'>
            <div className='domaine_row'>
                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_green'>
                        <CloudIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>CLOUD ET SERVICES HÉBERGÉ</h3>
                        <p>
                            Migration cloud, IaaS, PaaS, SaaS, AWS, Azure,
                            Google Cloud, Gestion multi-cloud, Sécurité
                            cloud
                        </p>
                    </div>
                </div>

                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_green'>
                        <FormationIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>FORMATION</h3>
                        <p>
                            Formation technique, Développement
                            professionnel, E-learning, Coaching,
                            Certifications IT
                        </p>
                    </div>
                </div>
            </div>

            <div className='domaine_row'>
                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_orange'>
                        <DataIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>EXPERTISE DATA</h3>
                        <p>
                            Big Data, Data Science, Machine Learning,
                            Business Intelligence, Data Governance
                        </p>
                    </div>
                </div>

                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_orange'>
                        <AgricoleIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>EXPERTISE AGRICOLE</h3>
                        <p>
                            Agro-foresterie, Gestion des ressources
                            naturelles, Agriculture de précision, Systèmes
                            d'irrigation, Durabilité agricole
                        </p>
                    </div>
                </div>
            </div>

            <div className='domaine_row'>
                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_green'>
                        <AppliIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>INGÉNIÉRIE APPLICATIVE</h3>
                        <p>
                            Développement logiciel, DevOps, Microservices,
                            Architecture logicielle, Intégration continue
                        </p>
                    </div>
                </div>

                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_green'>
                        <SecurityIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>SÉCURITÉ INFORMATIQUE</h3>
                        <p>
                            Cyber sécurité, Gestion des risques, Conformité
                            (GDPR, ISO 27001), Sécurité des applications,
                            Réponse aux incidents
                        </p>
                    </div>
                </div>
            </div>

            <div className='domaine_row'>
                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_orange'>
                        <NetworkIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>INFRASTRUCTURE & RÉSEAUX</h3>
                        <p>
                            Virtualisation, Réseaux LAN/WAN, SDN (SoftwareDefined Networking), Gestion de datacenters,
                            Haute disponibilité
                        </p>
                    </div>
                </div>

                <div className="domaine_content">
                    <div className='domaine_icon domaine_icon_orange'>
                        <InfogerenceIcon size={50} />
                    </div>
                    <div className="domaine_info">
                        <h3>INFOGÉRANCE</h3>
                        <p>
                            Gestion des opérations IT, Monitoring, Support
                            technique, Maintenance proactive, Gestion des
                            incidents
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DomaineActivite;