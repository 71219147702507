import React, { useEffect, useState } from "react";

import UCPlogo from "../Assets/UCP.png";
import OrangeLogo from "./../Assets/partenaires/Orange.png";
import IbmLogo from "./../Assets/partenaires/ibm.png";
import BiciciLogo from "./../Assets/partenaires/BICICI.png";
import BenetedLogo from "./../Assets/partenaires/bneted.png";
import AwsLogo from "./../Assets/partenaires/AWS.png";
import HuaweiLogo from "./../Assets/partenaires/huawei.png";
import SanteLogo from "./../Assets/partenaires/sante.jpg";
import AzureLogo from "./../Assets/partenaires/azure.png";
import CiscoLogo from "./../Assets/partenaires/cisco.png";
import PfsenseLogo from "./../Assets/partenaires/pfsense.png";
import TransportLogo from "./../Assets/partenaires/transport.png";
import WinlogicLogo from "./../Assets/partenaires/WINLOGIC.png";

import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 12,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1366 },
    items: 9,
  },
  tablet: {
    breakpoint: { max: 1366, min: 930 },
    items: 6,
  },
  mobile1: {
    breakpoint: { max: 930, min: 630 },
    items: 4,
  },
  mobile2: {
    breakpoint: { max: 630, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Patner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const element = document.querySelector(".AnimationDiv");
    const position = element.getBoundingClientRect();

    if (position.top < window.innerHeight) {
      setIsVisible(true);
    }
  };

  return (
    <div
      id="Partenaire"
      className={`Partenaire ${isVisible ? "AnimationDiv" : ""}`}
    >
      <Carousel responsive={responsive} autoPlaySpeed={2000} itemClass="px-1 carousel_items" containerClass="carousel_content" arrows={false} autoPlay infinite={true}slidesToSlide={1} rewind={true}>
        <div className="partenaire_img_content">
            <img src={OrangeLogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
            <img src={IbmLogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
            <img src={BiciciLogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
        
            <img src={TransportLogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
         
            <img src={UCPlogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
            <img src={WinlogicLogo} alt="efd" />
        </div>
        <div className="partenaire_img_content">
            <img src={BenetedLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={AwsLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={HuaweiLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={SanteLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={AzureLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={CiscoLogo} alt="efd" />
        </div>

        <div className="partenaire_img_content">
            <img src={PfsenseLogo} alt="efd" />
        </div>
      </Carousel>
    </div>
  );
};

export default Patner;
