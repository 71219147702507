import React from "react";

const FormationIcon = (props) => {
  return (
    <svg
      fill="#ffffff"
      height={props.size}
      width={props.size}
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
    >
      <g>
        <g>
          <circle cx="384.71" cy="40.684" r="40.213" />
        </g>
      </g>
      <g>
        <g>
          <path d="M332.855,363.552v102.227c0,12.859,10.424,23.285,23.285,23.285s23.285-10.425,23.285-23.285V363.552H332.855z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon points="436.046,290.3 436.046,290.3 436.046,290.301 			" />
            <path
              d="M409.612,244.472c-1.526,8.116-8.656,14.28-17.208,14.28h-59.556l0.009,31.548h46.569v-10.635h10.053V290.3h46.567
				c0-11.453-0.422-48.994-0.422-48.994L409.612,244.472z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <path d="M389.477,363.552v102.227c0,12.859,10.424,23.285,23.285,23.285s23.285-10.425,23.285-23.285V363.552H389.477z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M482.551,139.406c-0.128-25.192-20.725-45.687-45.918-45.687c-11.545,0-86.196,0-96.96,0
			c-13.935,0-26.947,6.219-35.701,17.059l-21.746,26.934l-20.381-40.504c-4.818-9.572-16.482-13.427-26.055-8.611
			c-9.572,4.817-13.428,16.482-8.611,26.055l33.908,67.383c6.332,12.58,23.567,14.445,32.43,3.467l13.176-16.318v-23.809
			c0-9.661,7.86-17.52,17.52-17.52h68.19c9.492,0,17.52,7.737,17.52,17.52v32.207l25.7-3.128v-33.995
			c0-2.101,1.585-3.862,3.675-4.082c2.09-0.22,4.01,1.17,4.447,3.225c0.219,43.509,0.192,38.27,0.24,47.715l-61.617,7.5
			c-10.638,1.295-18.211,10.969-16.916,21.606c1.293,10.622,10.947,18.211,21.606,16.917l78.762-9.588
			c9.774-1.19,17.108-9.512,17.058-19.359C482.698,168.571,482.798,188.489,482.551,139.406z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="142.322" cy="140.754" r="41.435" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M503.847,304.036H253.224c3.179-3.666,5.061-8.475,4.906-13.708c0-0.009-0.002-0.018-0.002-0.027
			c-0.315-10.124-8.034-19.786-22.339-19.362c-8.024-14.774-15.914-29.302-23.882-43.973c-2.186-4.025-7.224-5.518-11.248-3.33
			c-4.027,2.187-5.518,7.223-3.331,11.248c10.648,19.604,8.234,15.161,19.881,36.606l-43.927,1.303l-35.622-50.302l24.074,19.22
			l0.36-1.255c4.257-14.86-4.338-30.357-19.197-34.614l-45.785-13.115c-15.674-4.491-31.884,5.334-35.135,21.329L34.372,349.836
			c-1.631,8.021,0.934,16.501,7.188,22.293c-27.488,0-26.231-0.131-28.541,0.367C5.606,373.909,0,380.415,0,388.238v107.254
			c0,8.856,7.178,16.034,16.034,16.034c8.856,0,16.034-7.179,16.034-16.034v-91.293h74.056v91.293
			c0,8.856,7.178,16.034,16.034,16.034s16.034-7.179,16.034-16.034v-94.361l33.645,94.411c4.46,12.515,18.218,19.04,30.729,14.582
			c12.513-4.459,19.04-18.217,14.582-30.729l-43.353-121.658c-2.516-7.059-8.178-12.543-15.314-14.83l-23.578-7.557l2.186-7.629
			c-3.296-2.928-5.865-6.664-7.355-10.922l-24.792-71.14l41.834,59.073c3.765,5.315,9.865,8.459,16.355,8.459
			c0.198,0,0.397-0.002,0.595-0.009l40.889-1.213h0.011c-0.002,0.074-0.011,0.147-0.011,0.221v29.475
			c0,4.502,3.65,8.154,8.153,8.154h12.774c0,5.756,0,138.57,0,145.119c0,9.162,7.428,16.591,16.591,16.591
			s16.591-7.428,16.591-16.591c0-6.551,0-139.364,0-145.119h199.091c0,5.756,0,138.57,0,145.119c0,9.162,7.428,16.591,16.591,16.591
			s16.591-7.429,16.591-16.593c0-6.551,0-139.364,0-145.119h12.851c4.501,0,8.15-3.647,8.153-8.148c0-0.002,0-0.003,0-0.006v-29.475
			c0,0,0-0.002,0-0.003C511.997,307.684,508.348,304.036,503.847,304.036z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M392.404,141.125h-68.19c-2.343,0-4.248,1.906-4.248,4.248v95.858c0,2.343,1.905,4.249,4.248,4.249h49.775
			c-11.658-3.99-20.625-14.341-22.215-27.395c-2.215-18.19,10.737-34.733,28.929-36.947l15.948-1.942v-33.823
			C396.651,143.03,394.746,141.125,392.404,141.125z"
          />
        </g>
      </g>
    </svg>
  );
};

export default FormationIcon;
