import React from "react";

const CloudIcon = (props) => {
  return (
    <svg
      fill="#ffffff"
      width={props.size}
      height={props.size}
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M20,48H44V43H20Zm12-3h2v2H32Zm-3,0h2v2H29Zm-7,0h6v2H22Z" />
      <path d="M44,36H20v5H44ZM28,40H22V38h6Zm3,0H29V38h2Zm3,0H32V38h2Z" />
      <path d="M9,31h9V28a1,1,0,0,1,1-1H45a1,1,0,0,1,1,1v3h4a10,10,0,0,0,0-20h-.61a1,1,0,0,1-.943-.666,9.486,9.486,0,0,0-17.573-.795,1,1,0,0,1-1.619.285A9.5,9.5,0,0,0,13,16.5V20a1,1,0,0,1-1,1H9A5,5,0,0,0,9,31Zm47-7H54V22h2ZM50,14a6.006,6.006,0,0,1,6,6H54a4,4,0,0,0-4-4Z" />
      <path d="M44,29H20v5H44ZM28,33H22V31h6Zm3,0H29V31h2Zm3,0H32V31h2Z" />
      <path d="M62,57H35.858A4,4,0,0,0,33,54.142V49H31v5.142A4,4,0,0,0,28.142,57H2v2H28.142a3.981,3.981,0,0,0,7.716,0H62ZM32,60a2,2,0,1,1,2-2A2,2,0,0,1,32,60Z" />
    </svg>
  );
};

export default CloudIcon;
