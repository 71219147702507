import React from "react";

const NetworkIcon = (props) => {
  return (
    <svg
      fill="#ffffff"
      width={props.size}
      height={props.size}
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M37,9H35V7h2ZM33,9H31V7h2ZM29,9H27V7h2Z" />
      <path d="M15,41H13V39h2Zm0-4H13V35h2Zm0-4H13V31h2Zm0-4H13V27h2Zm0-4H13V23h2Z" />
      <path d="M37,51H35V49h2Zm-4,0H31V49h2Zm-4,0H27V49h2Z" />
      <path d="M50,41H48V39h2Zm0-4H48V35h2Zm0-4H48V31h2Zm0-4H48V27h2Zm0-4H48V23h2Z" />
      <path d="M42,48a1,1,0,0,1,1-1H55a1,1,0,0,1,1,1v8h1V46H41V56h1Z" />
      <path d="M42,6a1,1,0,0,1,1-1H55a1,1,0,0,1,1,1v8h1V4H41V14h1Z" />
      <path d="M41,60H57a3.006,3.006,0,0,0,2.829-2H38.171A3.006,3.006,0,0,0,41,60Z" />
      <path d="M7,60H23a3.006,3.006,0,0,0,2.829-2H4.171A3.006,3.006,0,0,0,7,60Z" />
      <rect height="7" width="10" x="44" y="49" />
      <rect height="7" width="10" x="10" y="49" />
      <rect height="7" width="10" x="44" y="7" />
      <path d="M41,18H57a3.006,3.006,0,0,0,2.829-2H38.171A3.006,3.006,0,0,0,41,18Z" />
      <path d="M8,48a1,1,0,0,1,1-1H21a1,1,0,0,1,1,1v8h1V46H7V56H8Z" />
      <path d="M25.514,26.656c-1.4-.153-2.72-.357-3.92-.607A11.879,11.879,0,0,0,20.051,31h4.973A26.75,26.75,0,0,1,25.514,26.656Z" />
      <path d="M25.024,33H20.051a11.879,11.879,0,0,0,1.543,4.951c1.2-.25,2.518-.454,3.92-.607A26.75,26.75,0,0,1,25.024,33Z" />
      <path d="M22.848,39.743a12.03,12.03,0,0,0,4.928,3.475,14.668,14.668,0,0,1-1.77-3.918C24.892,39.416,23.834,39.565,22.848,39.743Z" />
      <path d="M7,18H23a3.006,3.006,0,0,0,2.829-2H4.171A3.006,3.006,0,0,0,7,18Z" />
      <path d="M27.515,37.159C28.962,37.054,30.469,37,32,37s3.038.054,4.485.159A24.591,24.591,0,0,0,36.977,33H27.023A24.591,24.591,0,0,0,27.515,37.159Z" />
      <path d="M8,6A1,1,0,0,1,9,5H21a1,1,0,0,1,1,1v8h1V4H7V14H8Z" />
      <rect height="7" width="10" x="10" y="7" />
      <path d="M41.152,24.257a12.03,12.03,0,0,0-4.928-3.475,14.668,14.668,0,0,1,1.77,3.918C39.108,24.584,40.166,24.435,41.152,24.257Z" />
      <path d="M27.776,20.782a12.03,12.03,0,0,0-4.928,3.475c.986.178,2.044.327,3.158.443A14.668,14.668,0,0,1,27.776,20.782Z" />
      <path d="M36.485,26.841C35.038,26.946,33.531,27,32,27s-3.038-.054-4.485-.159A24.591,24.591,0,0,0,27.023,31h9.954A24.591,24.591,0,0,0,36.485,26.841Z" />
      <path d="M35.965,24.87C34.98,21.838,33.442,20,32,20s-2.98,1.838-3.965,4.87c1.283.083,2.611.13,3.965.13S34.682,24.953,35.965,24.87Z" />
      <path d="M38.976,31h4.973a11.879,11.879,0,0,0-1.543-4.951c-1.2.25-2.518.454-3.92.607A26.75,26.75,0,0,1,38.976,31Z" />
      <path d="M28.035,39.13C29.02,42.162,30.558,44,32,44s2.98-1.838,3.965-4.87C34.682,39.047,33.354,39,32,39S29.318,39.047,28.035,39.13Z" />
      <path d="M38.486,37.344c1.4.153,2.72.357,3.92.607A11.879,11.879,0,0,0,43.949,33H38.976A26.75,26.75,0,0,1,38.486,37.344Z" />
      <path d="M36.224,43.218a12.03,12.03,0,0,0,4.928-3.475c-.986-.178-2.044-.327-3.158-.443A14.668,14.668,0,0,1,36.224,43.218Z" />
    </svg>
  );
};

export default NetworkIcon;
